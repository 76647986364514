import React from "react";
import Footer from '../components/footer'
import "../css/career.css"; 
import NavBar from '../components/navbar'


// styles

export default class Career extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }render(){
    const pageStyles = {
      color: "#232129",
      padding: 96,
      fontFamily: "-apple-system, Roboto, sans-serif, serif",
    }
    const headingStyles = {
      marginTop: 0,
      marginBottom: 64,
      maxWidth: 320,
    }
    const headingAccentStyles = {
      color: "#663399",
    }
    const paragraphStyles = {
      marginBottom: 48,
    }
    const codeStyles = {
      color: "#8A6534",
      padding: 4,
      backgroundColor: "#FFF4DB",
      fontSize: "1.25rem",
      borderRadius: 4,
    }
    const listStyles = {
      marginBottom: 96,
      paddingLeft: 0,
    }
    const listItemStyles = {
      fontWeight: 300,
      fontSize: 24,
      maxWidth: 560,
      marginBottom: 30,
    }
    
    const linkStyle = {
      color: "#8954A8",
      fontWeight: "bold",
      fontSize: 16,
      verticalAlign: "5%",
    }
    
    const docLinkStyle = {
      ...linkStyle,
      listStyleType: "none",
      marginBottom: 24,
    }
    
    const descriptionStyle = {
      color: "#232129",
      fontSize: 14,
      marginTop: 10,
      marginBottom: 0,
      lineHeight: 1.25,
    }
    
    const docLink = {
      text: "Documentation",
      url: "https://www.gatsbyjs.com/docs/",
      color: "#8954A8",
    }
    
    const badgeStyle = {
      color: "#fff",
      backgroundColor: "#088413",
      border: "1px solid #088413",
      fontSize: 11,
      fontWeight: "bold",
      letterSpacing: 1,
      borderRadius: 4,
      padding: "4px 6px",
      display: "inline-block",
      position: "relative",
      top: -2,
      marginLeft: 10,
      lineHeight: 1,
    }
    
    // data
    const links = [
      {
        text: "Tutorial",
        url: "https://www.gatsbyjs.com/docs/tutorial/",
        description:
          "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
        color: "#E95800",
      },
      {
        text: "How to Guides",
        url: "https://www.gatsbyjs.com/docs/how-to/",
        description:
          "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
        color: "#1099A8",
      },
      {
        text: "Reference Guides",
        url: "https://www.gatsbyjs.com/docs/reference/",
        description:
          "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
        color: "#BC027F",
      },
      {
        text: "Conceptual Guides",
        url: "https://www.gatsbyjs.com/docs/conceptual/",
        description:
          "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
        color: "#0D96F2",
      },
      {
        text: "Plugin Library",
        url: "https://www.gatsbyjs.com/plugins",
        description:
          "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
        color: "#8EB814",
      },
      {
        text: "Build and Host",
        url: "https://www.gatsbyjs.com/cloud",
        badge: true,
        description:
          "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
        color: "#663399",
      },
    ]
  return (
  <div>  
  <div>
  <NavBar />
  </div>
  <div class="w-full vh-half banner-color flex">
  <div className="mx-auto container  self-center">
   <div className="job-title-content text-center">
    <h1 class="service-banner-title text-7xl md:text-7xl text-white pt-16 md:leading-snug lg:mb-2 font-extrabold uppercase">Recent Jobs</h1>
   </div>
   </div>
   </div>
   <div className="job-require-section mt-12 w-full">
   <div className="container mx-auto">
    <ul class="border-2 rounded-2xl">
      <li class="p-2 txt-hov divide-black border-solid border-b list">
        <a href="/career-form">
          <div class="inline-block img-cent align-middle w-28">
            <img class="career-img" src="../b-logo.png"/>
          </div>
          <div class="inline-block lg:ml-8 ml-20 align-middle w-2/5">
            <h4 class="text-2xl font-color uppercase">P.E Trainer</h4>
            <p>Fitlane Sports</p>
          </div>
          <div class="inline-block ml-8 align-middle w-1/5">
            <p class="text-xl w-28">Location: Tamil Nadu</p>
          </div>
          <div class="inline-block lg:ml-8 ml-20 align-middle w-1/5">
            <p class="full-time-color text-center rounded-lg">full-time</p>
          </div>
        </a>
      </li>

      <li class="p-2 txt-hov divide-black border-solid border-b list">
        <a href="/career-form">
          <div class="inline-block img-cent align-middle w-28">
            <img class="career-img" src="../b-logo.png"/>
          </div>
          <div class="inline-block lg:ml-8 ml-20 align-middle w-2/5">
            <h4 class="text-2xl font-color uppercase">Sales Manager</h4>
            <p>Fitlane Sports</p>
          </div>
          <div class="inline-block ml-8 align-middle w-1/5">
            <p class="text-xl w-28">Location: Tamil Nadu</p>
          </div>
          <div class="inline-block lg:ml-8 ml-20 align-middle w-1/5">
            <p class="full-time-color text-center rounded-lg">full-time</p>
          </div>
        </a>
      </li>

      <li class="p-2 txt-hov list">
        <a href="/career-form">
          <div class="inline-block img-cent align-middle w-28">
            <img class="career-img" src="../b-logo.png"/>
          </div>
          <div class="inline-block lg:ml-8 ml-20 align-middle w-2/5">
            <h4 class="text-2xl font-color uppercase">P.E Trainer</h4>
            <p>Fitlane Sports</p>
          </div>
          <div class="inline-block ml-8 align-middle w-1/5">
            <p class="text-xl w-28">Location: Tamil Nadu</p>
          </div>
          <div class="inline-block lg:ml-8 ml-20 align-middle w-1/5">
            <p class="part-time-color text-center rounded-lg">part-time</p>
          </div>
        </a>
      </li>
    </ul>
    </div>
   <div className="w-full mt-12  bg-gray-200">
   <div className="container mx-auto">
    <div class="border-2 rounded-xl p-8 grid  grid-cols-1 lg:grid-cols-4 flex">
      <div className=" col-span-1 lg:col-span-3">
        <h1 class="text-3xl inline-block mb-8 lg:mb-0 flex uppercase font-extrabold text-center">We’ll keep you updated when the <br/> best new remote jobs pop up.</h1>
      </div>
      <div className="col-span-1 pt-4 flex">
        <div class="inline-block mx-auto self-center">
          <a href="/contact" class="bg-transparent uppercase rounded shadow hover:shadow-lg py-2 px-4 btn-color">Contact Us</a>
        </div>
      </div>
    </div>
   </div>
    </div>
  </div>
  <div>
    <Footer />
  </div>
  </div>
  );
}
}
            
    
